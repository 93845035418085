import React from "react";
import { BsArrowRight } from "react-icons/bs";

export default function MainBtn(props){
    return(
        <div className="">
            {props.service === 'bath' && (
                <button onClick={() => props.setIsOpen(!props.isOpen)}
                    className="hover:shadow-lg font-txt-font flex items-center space-x-2 bg-main-color lg:bg-[#fcfaf5] text-white lg:text-black border-2 border-main-color hover:bg-main-color hover:-translate-y-2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-lg text-md py-3 px-12 group cursor-pointer md:tracking-wide">
                    <span className="">{props.name}</span>
                    <BsArrowRight />
                </button>
            )}
            {props.service !== 'bath' && (
                <button onClick={() => window.location.href = "tel:4088988473"}
                    className="hover:shadow-lg font-txt-font flex items-center space-x-2 bg-main-color lg:bg-[#fcfaf5] text-white lg:text-black border-2 border-main-color hover:bg-main-color hover:-translate-y-2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-lg text-md py-3 px-12 group cursor-pointer md:tracking-wide">
                    <span className="">{props.name}</span>
                    <BsArrowRight />
                </button>
            )

            }
        </div>
    );
};