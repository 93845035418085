import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Gallery, Item } from 'react-photoswipe-gallery';
import ReactPaginate from 'react-paginate';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';


function Popup (props){
            
    const handleButtonClick = () => {
        props.setIsOpen(!props.isOpen);
    };
    
    const [currentPageIndex, setPageCount] = useState(0);
    const limit = 6;

    const endOffset = currentPageIndex + limit;
    const currentItems = props.data.slice(currentPageIndex, endOffset);
    const pageC = Math.ceil(props.data.length / limit);

    const handlePageClick = (e) => {
        const newOffset = e.selected * limit;
        setPageCount(newOffset);
    };

    return (
		<div className={`${props.isOpen? "opacity-100" : "hidden" } px-2 fixed left-0 top-0 inset-0 z-50 flex flex-col h-screen w-screen items-center justify-center bg-black bg-opacity-80`}>
			<div className=' absolute top-3 lg:top-10 right-2 lg:right-8'>
                <button className = "  p-2" onClick={handleButtonClick}>
                    <FontAwesomeIcon className=' text-lg lg:text-3xl text-white' icon={faXmark} />
                </button>
            </div>
            <div className="cursor-pointer px-4 mx-auto max-w-screen-xl overflow-x-hidden">
                <div className="grid md:grid-cols-3 grid-cols-2 justify-items-center gap-2 cursor-pointer">
                    <Gallery options={props.options}>
                        {currentItems.map((fname, index) => (
                            <div key={index} className="p-1 shadow-lg md:p-2">
                                <Item width="900" height="900" original={fname} alt="#">
                                    {({ ref, open }) => (
                                        <div className="sticky h-full">
                                            <img ref={ref} onClick={open} className="rounded-lg w-[395px] h-[200px] md:h-[300px]" alt="#" src={fname} />
                                            <div ref={ref} onClick={open} className="hidden absolute p-8 inset-0 z-10 bg-black lg:flex flex-col justify-center opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">
                                                <div className=" bg-black/20 p-1 lg:p-4">
                                                    <div className=" flex justify-center text-lg text-white font-thin space-x-1">
                                                        <span className="">View Image</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Item>
                            </div>
                        ))}
                    </Gallery>
                </div>
            </div>

            <ReactPaginate
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                breakLabel={<span className="mr-4 text-second-color">...</span>}
                pageCount={pageC}
                marginPagesDisplayed={0}
                pageRangeDisplayed={0}
                onPageChange={handlePageClick}
                containerClassName="pagination flex items-center justify-center mt-7 mb-4"
                pageClassName="block text-white border-1 border-solid border-lightGray hover:bg-lightGray w-10 h-10 flex items-center justify-center rounded-md"
                previousClassName="page-item text-center p-2 border-2 group shadow-lg hover:bg-main-color border-main-color w-12 rounded-lg"
                previousLinkClassName="page-link group-hover:text-white text-main-color"
                nextClassName="page-item text-center p-2 border-2 shadow-lg group hover:bg-main-color border-main-color w-12 rounded-lg"
                nextLinkClassName="page-link group-hover:text-white text-main-color"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="font-bold"
                disabledClassName="text-white font-bold"
            />
		</div>
    );
};

export default Popup;