import {React , useState} from "react";
import Logo1 from "../img/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF , faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faBarsStaggered ,faXmark , faPhone} from "@fortawesome/free-solid-svg-icons"


function Navbar(props){
    const [Click , setClick] = useState(false);
    const [Scroll , SetScroll] = useState(false);

    const Icon_Style = {fontSize: '22'};
    
    const Change_color = () => {
        if(window.scrollY >= 10){
            SetScroll(true);
        }
        else{SetScroll(false);}
    };
    window.addEventListener('scroll' , Change_color)
    
    return (
        <div className="">
            <nav className= {Scroll?"bg-black/80 flex justify-between p-2 lg:justify-around lg:py-4 shadow-md w-full fixed top-0 left-0 right-0 z-10 ":
            "flex text-white justify-between p-2 lg:justify-around lg:py-2 bg-none w-full fixed top-0 left-0 right-0 z-10"}>
                
                <div className="flex lg:hidden rounded-full items-center space-x-1 cursor-pointer group">
                    <a onClick={() => setClick(!Click)} href="/">
                        <img src={Logo1} alt="" className=" w-28 group-hover:opacity-70" />
                    </a>
                </div>

                <div className={Scroll?"lg:hidden text-3xl cursor-pointer flex items-center text-black space-x-4": 
                "lg:hidden space-x-4 blkock text-3xl cursor-pointer flex items-center text-[#3C6CA8]"}>
                    <div onClick={() => window.location.href = "tel:4088988473"} className={`${Scroll? "bg-green-400" : "border-0" } p-2 rounded-full`}>
                        <FontAwesomeIcon className= {`${Scroll? "text-white" : "text-green-400"} flex !text-xl`} icon = {faPhone}/>
                    </div>
                    <div onClick={() => setClick(!Click)} className={Scroll?"lg:hidden text-3xl cursor-pointer flex items-center text-black space-x-4": "lg:hidden space-x-4 text-3xl cursor-pointer flex items-center text-[#3C6CA8]"}>
                        {!Click? 
                            <FontAwesomeIcon className=" text-main-color" icon = {faBarsStaggered}/> : 
                            <FontAwesomeIcon className=" text-main-color" icon={faXmark} />
                        }
                    </div>
                </div>
                <div className= {Scroll? "lg:flex text-md hidden items-center space-x-12  text-xl capitalize text-white" : 
                "lg:flex text-md hidden items-center space-x-12 text-xl capitalize text-white" }>
                        <a onClick={() => props.handleTabClick("Home")} className={`${props.isActive("Home")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/">
                            Home
                        </a>
                        <a onClick={() => props.handleTabClick("#Portfolio")} className={`${props.isActive("#Portfolio")} flex hover:text-main-color transition ease-in-out delay-150 duration-200`} href="/#Portfolio">
                            Portfolio
                        </a>
                </div>

                <div className="lg:flex hidden rounded-full items-center cursor-pointer group">
                    <a onClick={() => setClick(!Click)} href="/">
                        <img src={Logo1} alt="" className=" w-28 group-hover:opacity-70" />
                    </a>
                </div>

                <div className = {Scroll? "hidden lg:flex items-center space-x-12 text-white": "hidden lg:flex items-center space-x-12 text-white"}>
                    <a className="cursor-pointer flex" rel="noreferrer" href = "dasd">
                        <FontAwesomeIcon className="hover:text-rose-300 transition ease-in-out delay-150 duration-200" style={Icon_Style}  icon={faInstagram}></FontAwesomeIcon>
                    </a>
                    <a className="cursor-pointer flex" rel="noreferrer" href = "dsa">
                        <FontAwesomeIcon className="hover:text-sky-300 transition ease-in-out delay-150 duration-200" style={Icon_Style} icon={faFacebookF}></FontAwesomeIcon>
                    </a>
                    <button
                        className= {Scroll? "hover:bg-main-color group cursor-pointer px-4 py-3 border-main-color border-2 capitalize":
                        "bg-main-color  hover:bg-white/20 group cursor-pointer px-4 py-3 hover:shadow-md duration-200 ease-in-out delay-100 capitalize"}>
                        <a href="tel:4088988473" className = {Scroll?"group-hover:text-white tracking-wider uppercase":
                        "group-hover:text-white tracking-wider uppercase"}>Contact Us</a>
                    </button>
                </div>
            </nav>
            {Click?
                <nav className="overflow-hidden space-y-12 md:space-y-24 top-28 md:top-24 fixed p-6 w-screen h-screen bg-[#fcfaf5] 
                transition duration-700 ease-out lg:hidden left-0 right-0 z-20  mx-auto  text-black">
                    <div className=" space-y-16  md:space-y-24 flex flex-col text-lg mt-12">
                        <a onClick={() => setClick(!Click)} className="" href="/">Home</a>
                        <a onClick={() => setClick(!Click)} className=" " href="/#Portfolio">Portfolio</a>
                        <button
                            className="group  transition ease-out duration-200 cursor-pointer px-4 py-3 border-main-color border-2 capitalize tracking-wide">
                            <a href="tel:4088988473" className = "text-black">Contact Us</a>
                        </button>
                    </div>
                    <div className="flex space-x-10 text-2xl justify-center">
                        {/* <a className="cursor-pointer flex" target="_blank" rel="noreferrer" href = "https://www.instagram.com/nicespasac/">
                            <FontAwesomeIcon className="text-rose-300"  icon={faInstagram}></FontAwesomeIcon>
                        </a>
                        <a className="cursor-pointer flex" target="_blank" rel="noreferrer" href = "https://www.facebook.com/profile.php?id=61552561621667">
                            <FontAwesomeIcon className="text-sky-300"  icon={faFacebookF}></FontAwesomeIcon>
                        </a> */}
                    </div>
                </nav>
                :null
            }
        </div>
    );
};

export default Navbar;