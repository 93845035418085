import {React} from "react"
import Logo from "../img/Logo.png";
export default function Footer(){
    return(
        <footer className=" bg-white cursor-pointer">
            <div className="mx-auto max-w-screen-xl px-6 pb-6 pt-8 lg:px-8 lg:pt-24">
                <div className="grid lg:space-x-12 grid-cols-1 gap-8 lg:gap-12 lg:grid-cols-3">
                    <div className="grid justify-items-center">
                        <div>
                            <img alt ="#" className="flex w-32" src={Logo} />
                        </div> 
                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">About Us</p>
                            <ul className="mt-4 space-y-4 text-sm">
                                <li>
                                    <a href="/" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a  href="/#Portfolio" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Portfolio
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Services</p> 
                            <ul className="mt-4 space-y-4 text-sm">
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Home Remodeling
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        New Home Construction
                                    </span>
                                </li>
                                <li>
                                    <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                        Restaurant Construction
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Contact Us</p>
                            <ul className="mt-3 md:space-y-2 text-sm">
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="/">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 shrink-0 text-gray-900"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <a href="mailto:Andysjworkemail@gmail.com" className="flex-1 text-gray-700">Andysjworkemail@gmail.com</a>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:4088988473">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 shrink-0 text-green-400"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>
                                        <span className="flex-1 text-gray-700">(408) 898-8473</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
      
                <div className="mt-12 border-t border-gray-100 pt-6">
                    <div className="text-center sm:flex sm:justify-between sm:text-left">
                        <p className="text-sm text-gray-500">
                            <span className="block sm:inline">All rights reserved.</span>
                        </p>  
        
                        <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
                            &copy; 2024 T&T Construction
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};