import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


function Popup2 (props){
            
    const [isOpen2, setIsOpen2] = useState(false);

    const handleButtonClick = () => {
        setIsOpen2(!isOpen2);
        props.handleIsClick(!props.id);
    };

    return (
		<div className={`${!isOpen2? "flex" : " hidden" } px-2 fixed left-0 top-0 inset-0 z-50 flex-col h-screen w-screen items-center justify-center bg-black bg-opacity-80`}>
			<div className=' absolute top-3 lg:top-10 right-2 lg:right-8'>
                <button className = "  p-2" onClick={handleButtonClick}>
                    <FontAwesomeIcon className=' text-lg lg:text-3xl text-white' icon={faXmark} />
                </button>
            </div>
            <div className="cursor-pointer px-4 mx-auto max-w-screen-xl overflow-x-hidden">

                {props.data1.length === 1? 
                    (<div className="grid grid-cols-1 justify-items-center gap-2 cursor-pointer">
                        {props.data1.map((d,index) => (
                            <div onClick={() => window.open(d.link , '__blank')} key={index} className="sticky lg:h-full h-[220px] max-w-lg">
                                <div className=" cursor-pointer rounded-xl h-[220px] lg:h-full overflow-hidden group">
                                    <img alt="" className=" rounded-xl ease-in-out h-full group-hover:opacity-70 cursor-pointer transition-all duration-1000 group-hover:scale-105" src={d.img} />
                                    <div className="absolute lg:p-8 inset-0 space-y-2 lg:space-y-4 rounded-xl group-hover:bg-black/25 z-10 bg-black/25 flex flex-col justify-center items-center ease-linear duration-500 transition-all">
                                        <div className="">
                                            <div className="lg:text-2xl text-white font-extrabold">
                                                <span className="">{d.name}</span>
                                            </div>
                                        </div>
                                        <div className="lg:group-hover:flex lg:mt-4 ease-in-out transition-all duration-1000 lg:hidden">
                                            <div className="lg:text-md text-sm text-white font-bold border-2 px-4 py-2 rounded-full">
                                                <span className="">View All</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>):
                
                    (
                        <div className="grid grid-cols-2 justify-items-center gap-2 cursor-pointer">
                            {props.data1.map((d,index) => (
                                <div onClick={() => window.open(d.link , '__blank')} key={index} className="sticky lg:h-full h-[220px] max-w-lg">
                                    <div className=" cursor-pointer rounded-xl h-[220px] lg:h-full overflow-hidden group">
                                        <img alt="" className=" rounded-xl ease-in-out h-full group-hover:opacity-70 cursor-pointer transition-all duration-1000 group-hover:scale-105" src={d.img} />
                                        <div className="absolute lg:p-8 inset-0 space-y-2 lg:space-y-4 rounded-xl group-hover:bg-black/25 z-10 bg-black/25 flex flex-col justify-center items-center ease-linear duration-500 transition-all">
                                            <div className="">
                                                <div className="lg:text-2xl text-white font-extrabold">
                                                    <span className="">{d.name}</span>
                                                </div>
                                            </div>
                                            <div className="lg:group-hover:flex lg:mt-4 ease-in-out transition-all duration-1000 lg:hidden">
                                                <div className="lg:text-md text-sm text-white font-bold border-2 px-4 py-2 rounded-full">
                                                    <span className="">View All</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
		</div>
    );
};

export default Popup2;