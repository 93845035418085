import {Swiper , SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import React from 'react';
import { Gallery as Glery, Item } from "react-photoswipe-gallery";
import 'photoswipe/dist/photoswipe.css';

export default function SwiperReview(props) { 
    
    const options = {
        zoom: false,
        tapAction: 'close',
        doubleTapAction: false,
    };

    return (
        <Glery options={options}>
            <Swiper
                loop={true}
                breakpoints={{
                    340: {
                        slidesPerView: 2,
                        spaceBetween: 8,
                    },
                    700: {
                        slidesPerView: 4,
                        spaceBetween: 15,
                    }
                }}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    dynamicBullets: true,
                    clickable: true
                }}
                modules={[Autoplay]}
            >
                {props.data.map((fname, index) => (

                    <SwiperSlide key={index}>
                        <Item
                            key={index} 
                            original={fname} 
                            width="500" 
                            height="900" 
                            alt="#"
                        >
                            {({ ref, open }) => (
                                <div className="sticky h-full cursor-pointer">
                                    <img ref={ref} onClick={open} className="rounded-lg" alt="#" src={fname} />
                                    <div onClick={open} className="hidden absolute p-8 inset-0 z-10 bg-black lg:flex flex-col justify-center opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">
                                        <div className="bg-black/20 p-1 lg:p-4">
                                            <div className="flex justify-center text-lg text-white space-x-1">
                                                <span>View Review</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Item>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Glery>
    )  
};