import { useState } from 'react';
import Navbar from './Components/Navbar';
import { Route, Routes, Navigate} from 'react-router-dom';
import Home from './Views/Home';
import Footer from './Components/Footer';

function App() {

	const [activeTab, setActiveTab] = useState("Home");

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};
  
	const isActive = (tabName) => {
		return activeTab === tabName ? " border-t-main-color border-t-2 p-2" : "";
	};


	return (
    	<main className=' font-Monterast overflow-hidden'>
			<Navbar handleTabClick = {handleTabClick} isActive = {isActive}/>
			<Routes>
				<Route path='/' element = {<Home />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
			<Footer />
    	</main>
  	);
}

export default App;
