import React, {useState} from "react";
import CarouselHome from "../Components/Carousel";
import { FaPencilRuler } from "react-icons/fa";
import { GoChecklist } from "react-icons/go";
import { FaRegLightbulb } from "react-icons/fa";
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import img1 from "../img/hayward.png";
import {Fade} from "react-awesome-reveal";
import MainBtn from "../Components/MainBtn";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Popup from "../Components/Popup";
import ser1 from "../img/ser/1.png";
import ser2 from "../img/ser/2.png";
import ser3 from "../img/ser/3.png";
import before1 from "../img/bef1.png";
import after1 from "../img/after.jpg";
import SwiperReview from "../Components/ReviewCarousel";

import por1 from "../img/95148 house remodel/main.png";
import por2 from "../img/Garage conversation /Main.png";
import por3 from "../img/Hayward remodel/Main.png";
import por5 from "../img/Willow Glenn/Main.png";
import por6 from "../img/Condo/Main.png";
import por7 from "../img/Fremont/main.jpg";
import por8 from "../img/Union City/Main.png";



import bef1 from "../img/95148 house remodel/Before/Before.png"
import bef2 from "../img/Garage conversation /Before/Before.png";
import bef3 from "../img/Hayward remodel/Before/Before.png";
import bef5 from "../img/Willow Glenn/Before/Before.png";
import bef7 from "../img/Fremont/Before/main.png";

import Popup2 from "../Components/Popup2";



function importImg(r) {
    return r.keys().map(r);
};

export default function Home(){

    const fnames = importImg(require.context('../img/Bathroom ideal/' , false , /\.(png|jpg|jpeg)$/));
    const ReviewData = importImg(require.context('../img/Review & logo/' , false , /\.(png|jpg|jpeg)$/));


    const options = {
        zoom: false,
        tapAction: 'close',
        doubleTapAction: false,
    };

    const [isOpen, setIsOpen] = useState(false);

	const [isClick , setIsClick] = useState(false);

    const handleBtn = (id) => {
        setIsClick((lastClick) => ({
            [id]: !lastClick[id],
        }));
    };


    const Section1 = [
        {
            id: 1,
            title: 'Top-Tier Craftsmanship',
            desc: 'We ensure every project meets the highest standards of durability and excellence.'
        },
        {
            id: 2,
            title: 'On-Time, On-Budget',
            desc: 'We keep your construction on time and within budget, with clear communication throughout.'
        },
        {
            id: 3,
            title: 'Custom Design Solutions',
            desc: 'We bring your vision to life, from modern remodels to custom restaurant builds.'
        },
    ];

    const Section2 = [
        {
            id: 1,
            name: 'Years Of Experiences',
            value: '10+',
        },
        {
            id: 2,
            name: 'Projects Completed',
            value: '20+',
        },
        {
            id: 3,
            name: 'Client Satisfaction Rate',
            value: '98%',
        },
        {
            id: 4,
            name: 'Team Size',
            value: '10+',
        }
    ];

    const Section3 = [
        {
            id: 1,
            name: 'Home Remodeling',
            desc: 'Elevate your living space with expert home remodeling. From kitchens to full makeovers, we deliver quality craftsmanship on time and within budget.'
        },
        {
            id: 2,
            name: 'New Home Construction',
            desc: 'Build your dream home with our expert construction services. We ensure precision and durability from design to completion.'
        },
        {
            id: 3,
            name: 'Restaurant Construction',
            desc: 'Create a unique dining experience with our restaurant construction services. We deliver high-quality builds that reflect your brand and meet your needs.'
        }
    ];

    const Section4 = [
        {
            id:1,
            img: ser1,
        },
        {
            id:2,
            img: ser2,
        },
        {
            id:3,
            img: ser3,
        },
    ];

    const Section5 = [
        {
            id: 1,
            name: "San Jose",
            img: por1,
            data: [
                {
                    name: 'Before',
                    img: bef1,
                    link: 'https://photos.google.com/u/5/share/AF1QipNNItQgezR3mYoRZleNuRGp-ffDOdemyl5rT_0Y3bomcPvZD_icbSXm_RWl9QTF3Q/photo/AF1QipNoENBxtD767fqdNPdHmOkLZjsDvwiab1rK_Gjj?key=N29aSk1JQzBESk9lUTRFbU9tMnloOHAzQzl6a3BB'
                },
                {
                    name: 'After',
                    img: por1,
                    link: 'https://photos.google.com/share/AF1QipNDzzUlmKPUzm4a0lRwXzr5pk3LM6Wv-eYcA52xZYG_XNEepjwZLnTjSrldj8USyw/photo/AF1QipMDWQsnVa__sF-deV55_ZPoprHzdWuiV5QVCMWN?key=TC03TzZUSkhfVHVEajJ0N3NfNnJLTzhFUVM3dUx3'
                }
            ]
        },
        {
            id: 2,
            name: "Hayward",
            data: [
                {
                    name: 'Before',
                    img: bef3,
                    link: 'https://photos.google.com/u/5/share/AF1QipPzpa3HRxIlj2tNSkGkbjtAnXuphwgiBmb_njxbEQZsCQBxhqigKW0Lrexbw4x6hg/photo/AF1QipP3ZTzf-HN7LTcPAN8jVsV9qTdgt7nhdbe2x6wV?key=VWFzLUVzbXpGUmI2eUpoVG5zMG9RNHdxcE00QVBR'
                },
                {
                    name: 'After',
                    img: por3,
                    link: 'https://photos.google.com/u/5/share/AF1QipO5F0yZuyw-t8wRnPidlVp6-gr8iNTytIdnTwO961gqab2mzawFagjiky9K-OtCZA/photo/AF1QipOM_KdgZ_GijCBf1cXrLpC5G6m9aSjf-OWMBuuG?key=R253bEJDZHVlVU9lWHdwT3Z1dl9wMDQxZjlzOE5R'
                }
            ],
            img: por3
        },
        {
            id: 3,
            name: "Garage Convert",
            data: [
                {
                    name: 'Before',
                    img: bef2,
                    link: 'https://photos.google.com/u/5/share/AF1QipPvC8qvQedcQc1AbuWPYITZR93YzQOTfDXxSHdYwijmV-UQmdK5gixHe80l5rLVIw/photo/AF1QipMqlU9cBgM8HT6T20l_nzGTvTWnpOXAIPmMOPE6?key=RVR1ajJqZUtDWXBjZmplVmZzSGQ0TnB6a243S3JR'
                },
                {
                    name: 'After',
                    img: por2,
                    link: 'https://photos.google.com/u/5/share/AF1QipN3fW008_IzczcL-xFjJ5yVLvTZOX51R3qAesN6L3nSjdbAGD853SHqgHKR7bU_vw/photo/AF1QipNVOSuvpGppuIhseFdkrIeXoyTU1HD2oD9HrjXF?key=c05zRTU0N0xQZWhtc29LX1puRG5sZDJmUmZJMFdn'
                }
            ],
            img: por2
        },
        {
            id: 4,
            name: "Willow Glen",
            data: [
                {
                    name: 'Before',
                    img: bef5,
                    link: 'https://photos.google.com/u/1/share/AF1QipMjowEUw9Ae4LHIa_bd3o4XLrHHc5Sszwm8oPF2A73bi-IQm4EqWhTRi8HGeexOng/photo/AF1QipO106IBISDpPIRCXfw2Mqjar9SFqabkkqyCHnX7?key=Y0NvUFA4bFFKTlh5dG92T0k1bXVkcGhNZG1kSUl3'
                },
                {
                    name: 'After',
                    img: por5,
                    link: 'https://photos.google.com/u/1/share/AF1QipOylneec_1MJcKkPIHn6D5UM93hkkmRqiXs6iAAYUVFj5VghoTmLEdh12GN5HfIcw/photo/AF1QipMyTAcAB0Ndosq4pD_1FFy4s6AFlL3UmqOoWx1w?key=T1FfUlZlV3FzdVlfTGJIREZZNWRLSmYyX3NPblpR'
                }
            ],
            img: por5
        },
        {
            id: 5,
            name: "San Jose Condo",
            data: [
                {
                    name: '',
                    img: por6,
                    link: 'https://photos.google.com/u/1/share/AF1QipPmqpSHhI2PLJcBfCJ5FD3scUZCWmubdlpCaIQTKLY0PS62uGzh2nsDyglfO42s6Q/photo/AF1QipPi4h1a5FOpwsuSa5hIa74s3RHmTCp0mql3QxDg?key=emVBLTJEVVYtMm43SnE1SkdIZl9JbTViWGlrRlp3'
                }
            ],
            img: por6
        },
        {
            id: 6,
            name: "Fremont",
            data: [
                {
                    name: 'Before',
                    img: bef7,
                    link: 'https://photos.google.com/u/1/share/AF1QipOyMIRYS-tg-DDEpSqapLyQi83QNhq_xupitBZE9y7FymMnywfLEtGpEFpdUOy-hA/photo/AF1QipNv-Wm2V7nw5XPPybhfND8GUct5H_O9vLQVXoRv?key=cnlzRFVBbDM1NE5kcmVMajRGbVZOaUwwck1IQ1FR'
                },
                {
                    name: 'After',
                    img: por7,
                    link: 'https://photos.google.com/u/1/share/AF1QipPnjvUjNfD4V7c_JoJ_j5WKJ6zMkynzZO0ig6Z1v3rGOE97CIuGMKHu4BVaRc537g/photo/AF1QipMWkKgs17lLWtGFl-7ETE5jKFMwb-Ax_Iw8zDwC?key=Z0k5dkVGWjREbGQ2MXpTbVJKWVV4X0hJSXpvWEtR'
                }
            ],
            img: por7
        },
        {
            id: 7,
            name: "Union City",
            data: [
                {
                    name: '',
                    img: por8,
                    link: 'https://photos.google.com/u/1/share/AF1QipOG0Y4jjA8k0da9R-Jd-iDpU51nZdkE4K2AZi_x_E4wxjQfps-XeI38Rz90qC-bVQ/photo/AF1QipNAK6MRhKyWDUejxubaAxmVYd6QShHFava6r4Bu?key=ZTIwQWkycFdQSG9QWHpnU2tEcTZESzNIc1hXN0dR'
                }
            ],
            img: por8
        },
    ];

    return(
        <main className=" relative">
            <section>
                <CarouselHome />
            </section>
            
            <section className=" bg-[#fcfaf5]">
                <div className="pt-6 pb-6 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-24 place-items-center lg:grid-cols-3">
                            {Section1.map((data) => (
                                <div key={data.id} className=" flex space-x-4 p-2 cursor-pointer">
                                    <div className=" bg-second-color h-fit shadow-md p-2">
                                        {
                                            data.id === 1 && (
                                                <FaPencilRuler className=" text-white text-3xl" />
                                            )
                                        }

                                        {
                                            data.id === 2 && (
                                                <GoChecklist className=" text-white text-3xl" />
                                            )
                                        }

                                        {
                                            data.id === 3 && (
                                                <FaRegLightbulb className=" text-white text-3xl" />
                                            )
                                        }
                                    </div>
                                    <div className=" flex flex-col space-y-1">
                                        <h1 className=" text-xl text-second-color font-bold">{data.title}</h1>
                                        <span className=" text-md text-txt-color font-thin">
                                            {data.desc}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col mx-2 space-y-3 lg:space-y-6 items-center">
                            <h1 className=" text-xl lg:text-3xl text-main-color font-bold">Quality - Safety - Reliability</h1>
                            <span className=" text-center text-md lg:text-xl text-txt-color">
                                At T&T Construction, we are committed to delivering exceptional construction services
                                that stand the test of time. We ensure every project is built to the highest standards, 
                                with a focus on meticulous craftsmanship and safe practices. 
                                You can count on us to manage each step of the process with precision, ensuring 
                                that your project is completed on time and with enduring results you can trust.
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg4 bg-center bg-no-repeat bg-cover">
                <div className=" w-full h-full bg-black bg-opacity-40">
                    <div className="pt-6 pb-6 lg:pt-24 lg:pb-24 space-y-4">
                        <div className="lg:p-4 p-2 space-y-12 mx-auto max-w-screen-xl">
                            <div className=" grid gap-6 lg:gap-24 place-items-center lg:grid-cols-4">
                                {Section2.map((data) => (
                                    <div key={data.id} className=" flex flex-col space-y-4 lg:space-y-6">
                                        <h1 className=" text-white text-center text-xl lg:text-xl">{data.name}</h1>
                                        <span className=" tracking-wider text-orange-200 text-center text-2xl lg:text-3xl">{data.value}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className=" p-5 pt-6 pb-6 lg:pt-24 lg:pb-24 bg-[#fcfaf5]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className=" grid gap-8 lg:grid-cols-2">
                        <Fade delay={500}>
                            <div className="flex flex-col space-y-4 lg:space-y-6">
                                <div className=" flex flex-col space-y-2">
                                    <span className=" text-3xl text-main-color">
                                        Expert Solutions
                                    </span>
                                </div>
                                <span className=" text-lg text-txt-color">
                                    We deliver tailored construction solutions, from remodels to new builds. 
                                    Count on our expert team for quality, precision, and innovation in every project.
                                </span>
                                <div className=" font-txt-font">
                                    <ul className=" space-y-4 lg:space-y-8">
                                        {Section3.map((data) => (
                                            <li key={data.id} className="flex space-y-1 cursor-pointer flex-col">
                                                <div className=" flex items-center space-x-2 text-xl lg:text-2xl">
                                                    <span className="">0{data.id}</span>
                                                    <span className=" text-second-color">
                                                        {data.name}
                                                    </span>
                                                    <IoMdCheckmarkCircleOutline className=" text-green-400" />
                                                </div>
                                                <span className=" text-txt-color mx-8 text-md">
                                                    {data.desc}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <MainBtn name = "Get Free Estimate" />
                            </div>
                        </Fade>
                        <div className=" lg:hidden flex">
                            <img className=" h-full" alt="da" src={img1} />
                        </div>
                        <div className="hidden lg:block lg:bg-bghay lg:w-full rounded-lg cursor-pointer hover:opacity-50 lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                        </div>
                    </div>
                </div>  
            </section>

            <section className=" p-5 pt-6 pb-6 lg:pt-24 lg:pb-12 bg-[#fcfaf5]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className=" grid gap-8 lg:grid-cols-2">
                        <Fade>
                            <div className="flex flex-col space-y-6">
                                <div className=" flex flex-col space-y-3">
                                    <span className=" text-2xl lg:text-3xl text-main-color">
                                        Affordable Remodeling with Premium Quality Materials
                                    </span>
                                    <span className=" lg:text-md text-txt-color font-bold">
                                        What’s our approach? Here’s how we make it happen.
                                    </span>
                                </div>
                                <span className=" lg:text-lg text-txt-color">
                                    At T&T Construction, we carefully select a curated range of finishing materials. 
                                    By purchasing in bulk, we secure the best prices, allowing us to pass those savings directly to you.                                
                                </span>
                                <span className=" lg:text-lg text-txt-color">
                                    Our efficient process also speeds up the timeline, so your kitchen or bathroom or living room remodel 
                                    is completed faster, delivering top-quality results at a price you’ll love.                                
                                </span>
                            </div>
                        </Fade>

                        <div className=" grid grid-cols-2 gap-2 mx-auto max-w-xl">
                            {Section4.map((data) => (
                                data.id !== 3?
                                    (
                                        <div key={data.id} className=" cursor-pointer rounded-xl overflow-hidden group">
                                            <img alt="" className=" rounded-xl ease-in-out hover:opacity-70 cursor-pointer transition-all duration-1000 hover:scale-105" src={data.img} />
                                        </div>
                                    ):
                                    (
                                        <div key={data.id}  className=" flex col-span-2 justify-center">
                                            <div className=" cursor-pointer w-1/2 rounded-xl overflow-hidden group">
                                                <img alt="" className=" rounded-xl ease-in-out hover:opacity-70 cursor-pointer transition-all duration-1000 hover:scale-105" src={data.img} />
                                            </div>
                                        </div>
                                    )
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-[#fcfaf5]">
                <div className="pt-6 pb-6 lg:pt-12 lg:pb-12">
                    <Fade>
                        <div className="lg:p-4 p-2 space-y-5 lg:space-y-10 mx-auto max-w-screen-xl">
                            <div className=" flex flex-col items-center space-y-4 text-center lg:space-y-6">
                                <div className="mx-2">
                                    <h1 className="text-2xl lg:text-3xl text-main-color">Transform Your Bathroom with Innovative Design Ideas</h1>
                                </div>
                                <div className="  lg:w-4/5">
                                    <span className="text-md lg:text-xl text-txt-color">
                                        Our efficient process also speeds up the timeline, so your kitchen or bathroom or living room remodel 
                                        is completed faster, delivering top-quality results at a price you’ll love.                                
                                    </span>
                                </div>
                            </div>
                            
                            <div className="cursor-pointer px-4 mx-auto max-w-screen-xl overflow-x-hidden">
                                <div className="grid md:grid-cols-3 grid-cols-2 justify-items-center gap-2 cursor-pointer">
                                    <Gallery options={options}>
                                        {fnames.slice(0,6).map((fname, index) => (
                                            <div key={index} className="p-1 shadow-lg md:p-2">
                                                <Item width="900" height="900" original={fname} alt="#">
                                                    {({ ref, open }) => (
                                                        <div className="sticky h-full">
                                                            <img ref={ref} onClick={open} className="rounded-lg w-[395px] h-[200px] md:h-[300px]" alt="#" src={fname} />
                                                            <div ref={ref} onClick={open} className="hidden absolute p-8 inset-0 z-10 bg-black lg:flex flex-col justify-center opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">
                                                                <div className=" bg-black/20 p-1 lg:p-4">
                                                                    <div className=" flex justify-center text-lg text-white font-thin space-x-1">
                                                                        <span className="">View Image</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Item>
                                            </div>
                                        ))}
                                    </Gallery>
                                </div>
                                <div className=" mt-4 lg:mt-6">
                                    <span className=" font-bold text-md lg:text-xl">Disclosure: These images are Found Online, Used for Sample Purposes</span>
                                </div>
                            </div>
                            <div className=" flex justify-center">
                                <MainBtn isOpen={isOpen} setIsOpen={setIsOpen} service = "bath" options = {options} name="View More" />
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>

            <section id="Portfolio" className=" bg-[#fcfaf5]">
                <div className="pt-6 pb-6 lg:pt-12 lg:pb-12">
                    <div className="lg:p-4 p-2 space-y-5 lg:space-y-10 mx-auto max-w-screen-xl">
                        <Fade>
                            <div className=" flex flex-col items-center space-y-5 text-center justify-center lg:space-y-12">
                                <div className="mx-2">
                                    <h1 className="text-2xl lg:text-3xl text-main-color">Our Portfolio</h1>
                                </div>
                                <div className="  lg:w-4/5">
                                    <span className="text-md lg:text-xl text-txt-color">
                                    Explore our diverse portfolio showcasing completed projects that highlight our expertise in home remodeling,
                                    new construction, and restaurant builds. Each project is a testament to our commitment to quality, 
                                    craftsmanship, and client satisfaction. See how T&T Construction brings visions to life with precision and attention to detail.                           
                                    </span>
                                </div>
                            </div>
                        </Fade>
                        <div className="cursor-pointer px-4 mx-auto max-w-screen-xl overflow-x-hidden">
                            <div className="grid md:grid-cols-3 grid-cols-1 gap-6 justify-items-center lg:gap-8 cursor-pointer">
                                {Section5.map((data) => (
                                    <main>
                                        <div onClick={() => handleBtn(data.id)} key={data.id} className="sticky h-full">
                                            <div className=" cursor-pointer rounded-xl overflow-hidden group">
                                                <img alt="" className=" rounded-xl ease-in-out group-hover:opacity-70 cursor-pointer transition-all duration-1000 group-hover:scale-105" src={data.img} />
                                                <div className="absolute p-8 inset-0 space-y-4 rounded-xl group-hover:bg-black/25 z-10 bg-black/25 flex flex-col justify-center items-center ease-linear duration-500 transition-all">
                                                    <div className="">
                                                        <div className="text-xl text-white font-extrabold">
                                                            <span className="">{data.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="lg:group-hover:flex mt-4 ease-in-out transition-all duration-1000 lg:hidden">
                                                        <div className="text-md text-white font-extrabold border-2 px-4 py-2 rounded-full">
                                                            <span className="">View Project</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {isClick[data.id] && (
                                            <Popup2 isOpen={isOpen} setIsOpen={setIsOpen} service = "bath" options = {options} name="View More" id = {data.id} handleIsClick = {handleBtn} data1={data.data} />
                                        )}
                                    </main>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" p-5 pt-6 pb-6 lg:pt-24 lg:pb-12 bg-[#fcfaf5]">
                <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                    <div className=" grid gap-10 lg:grid-cols-2">
                        <Fade>
                            <div className="flex flex-col space-y-6">
                                <div className=" flex flex-col space-y-3">
                                    <span className=" text-2xl lg:text-3xl text-main-color">
                                        No Surprises: Guaranteed On-Time, On-Budget Construction
                                    </span>
                                </div>
                                <span className=" lg:text-lg text-txt-color">
                                    At T&T Construction, we understand the concerns about unexpected costs and delays. That’s why we provide 
                                    transparent, upfront pricing and stick to our timelines with a clear, 
                                    detailed project plan. We keep you informed at every step, ensuring there are no surprises.
                                </span>
                                <span className=" lg:text-lg text-txt-color">
                                    Our commitment to reliability means your project will be completed on time and within budget—guaranteed.                            
                                </span>
                                <MainBtn name = "Get Free Estimate" />
                            </div>
                        </Fade>

                        <div className=" grid  grid-cols-2 gap-2 lg:gap-4 mx-auto max-w-xl">
                            <div className=" flex items-center space-y-2 lg:space-y-4 flex-col">
                                <img alt="" className="ease-in-out hover:opacity-70 cursor-pointer transition-all duration-1000 h-[150px] lg:h-[320px] rounded-xl" src={before1} />
                                <span className=" text-main-color text-xl lg:text-2xl">Before</span>
                            </div>
                            <div className=" flex items-center space-y-2 lg:space-y-4 flex-col">
                                <img alt="" className="ease-in-out hover:opacity-70 cursor-pointer transition-all duration-1000 h-[150px] lg:h-[320px] rounded-xl" src={after1} />
                                <span className=" text-main-color text-xl lg:text-2xl">After</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-[#fcfaf5]">
                <div className="pt-4 pb-6 lg:pt-12 lg:pb-12">
                    <div className="lg:p-4 p-2 space-y-5 lg:space-y-10 mx-auto max-w-screen-xl">
                        <div className=" flex flex-col space-y-5 text-center justify-center lg:space-y-12">
                            <div className="mx-2">
                                <h1 className="text-2xl lg:text-3xl text-main-color">Customer Feedback</h1>
                            </div>
                            <div className=" mx-2">                                
                                <SwiperReview data = {ReviewData} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {isOpen &&
                <Popup options={options} data={fnames.slice(6, fnames.length)} isOpen={isOpen} setIsOpen={setIsOpen} />
            }

        </main>

    );

};